import * as React from 'react';

import { Link } from 'gatsby';

import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import { StaticImage } from 'gatsby-plugin-image';

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]


export default function Hero() {

    return (
      <div className="relative overflow-hidden h-screen">


        <div className="block absolute inset-y-0 w-full" aria-hidden="true">
          <div className="relative h-full w-full">
            <div className="absolute inset-0">
              <img
                className="w-full h-full object-cover"
                src="https://res.cloudinary.com/dbs3cwu82/image/upload/v1/SalientApplications/rsmfgwdx9rxejkkpmnfq.jpg?_a=ATAMhAA0"
                alt="Hero Background Img"
              />
              <div
                className="absolute inset-0 mix-blend-multiply"
                aria-hidden="true"
                style={{ backgroundColor: "rgb(74, 74, 74)" }}
              />
            </div>
            <svg
              className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-[#2a9d8f]" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
            </svg>
          </div>
        </div>

        <div className="relative pt-6 pb-16 sm:pb-24 h-full">
          <Popover>
            <div className="max-w-7xl mx-auto px-4 sm:px-6">
              <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
                <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <a href="#" className="">
                      <span className="sr-only">Workflow</span>
                      
                      {/* <StaticImage src="../../../images/SalientApplications.png" width="400" alt="spiderman" /> */}
                      
                    </a>
                    {/* <StaticImage src="../../images/SalientApplications.png" alt="spiderman" layout='fixed' loading='eager' placeholder={'none'} /> */}
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button className="bg-[#2a9d8f] rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open main menu</span>
                        <MenuIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:flex md:space-x-10">
                <Link
                  to="/technology/"
                  className="font-medium text-gray-500"
                  style={{ color: "rgb(42, 157, 143)" }}
                >
                  Technology
                </Link>
                <Link
                  to="/pricing/"
                  className="font-medium text-gray-500"
                  style={{ color: "rgb(42, 157, 143)" }}
                >
                  Pricing
                </Link>
                <Link
                  to="/contact/"
                  className="font-medium text-gray-500"
                  style={{ color: "rgb(42, 157, 143)" }}
                >
                  Contact
                </Link>
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-md bg-[#264653] ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    {/* <a href="#" className="">
                      <span className="sr-only">Workflow</span>
                      <img
                        className="h-8 w-auto sm:h-10"
                        src="https://res.cloudinary.com/dbs3cwu82/image/upload/v1/SalientApplications/l1hmpq1ejwwsylqjx31t.png?_a=ATAMhAA0"
                        alt="logo img"
                      />
                    </a> */}
                    {/* <StaticImage src="../../../images/SalientApplications.png" height={120} width={550} alt="spiderman" /> */}
                    <div className="-mr-2">
                      <Popover.Button className="bg-[#2a9d8f] text-white rounded-md p-2 inline-flex items-center justify-center hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3">
                    {/* {navigation.map((item) => (
                      <a
                        key={item.name}
                        to={item.href}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))} */}
                    <Link
                      key={'Home'}
                      to={'/home/'}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-gray-900 hover:bg-gray-50"
                    >
                      Home
                    </Link>
                    <Link
                      key={'Technology'}
                      to={'/technology/'}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-gray-900 hover:bg-gray-50"
                    >
                      Technology
                    </Link>
                    <Link
                      key={'Pricing'}
                      to={'/pricing'}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-gray-900 hover:bg-gray-50"
                    >
                      Pricing
                    </Link>
                    <Link
                      key={'Contact'}
                      to={'/contact'}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-gray-900 hover:bg-gray-50"
                    >
                      Contact
                    </Link>
                  </div>
                  {/* <a
                    href="#"
                    className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-[#2a9d8f] hover:bg-gray-100"
                  >
                    Log in
                  </a> */}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <main className="h-full flex flex-col justify-center mx-auto max-w-7xl px-4">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-7xl">
              {/* <p className="text-7xl w-fit text-white py-4 border border-white"><span className="px-2 py-1 bg-[#264653]">S</span><span className="bg-white p-1 text-[#264653]">A</span></p> */}
              <span
                className="block xl:inline text-white mr-4 tracking-widest"
                style={{ fontFamily: 'times new roman', marginBottom: '2rem' }}
              >
              SALIENT
              </span>
              <span className="block xl:inline bg-white text-[#264653] p-4 tracking-widest m-4 text-3xl sm:text-4xl md:text-7xl" style={{ fontFamily: 'times new roman' }}>
                APPLICATIONS
              </span>
              </h1>
              <p
                className="mt-16 max-w-md mx-auto text-2xl sm:text-xl md:mt-4 md:text-xl md:max-w-6xl text-white leading-10"
                style={{ color: "", fontSize: 28, marginTop: '2.8rem' }}
              >
                <span className="bg-white p-1 text-[#264653]">Freelance</span> web development services
              </p>
              <div className="mt-3 max-w-md mx-auto text-base text-gray-300 sm:text-lg md:mt-5 md:text-xl md:max-w-6xl">
                <p>
                  <span>
                    {/* Your web presence is my <span className='bg-[#2a9d8f] p-1 border border-white text-white'>priority.</span> */}
                  </span>
                </p>
              </div>
              <div
                className="mt-5 max-w-md md:max-w-6xl mx-auto sm:flex md:mt-8"
                style={{ justifyContent: "center" }}
              >
                <div className="inline-flex rounded-md">
                  <Link
                    to="/contact/"
                    // className="inline-flex items-center justify-center px-5 py-4 m-2 border border-transparent text-base font-medium rounded-md w-full"
                    // style={{
                    //   backgroundColor: "rgb(38, 70, 83)",
                    //   color: "white",
                    //   border: "1px solid white",
                    //   marginLeft: 0
                    // }}
                    className="text-white bg-[#264653] border border-white rounded p-4 self-center m-1 hover:bg-white text-semibold hover:text-[#264653] hover:border-[#264653]"
                  >
                    Get In Touch
                  </Link>
                </div>
                <div className="inline-flex rounded-md">
                  <Link
                    to="/pricing/"
                    // className="inline-flex items-center justify-center px-5 py-4 m-2 border border-transparent text-base font-medium rounded-md w-full"
                    // style={{
                    //   backgroundColor: "rgb(42, 157, 143)",
                    //   color: "white",
                    //   border: "1px solid white"
                    // }}
                    className="text-white bg-[#2a9d8f] border border-white rounded p-4 self-center m-1 hover:bg-white text-semibold hover:text-[#264653] hover:border-[#264653]"
                  >
                    Pricing
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    )
}
