import * as React from 'react';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';


export default function BusinessCases() {
    
    return (
      <section className="py-12 md:py-20 overflow-x-hidden bg-gray-50 border-b border-b-gray-400">
        <div className="container px-4 mx-auto max-w-7xl">
          <div className="flex flex-wrap lg:flex-nowrap">
            <div className="w-full lg:w-1/2">
              <div className="py-6 lg:pr-32">
                <div className="mb-4">
                  <span className="text-xs py-1 px-3 text-white font-semibold bg-[#2A9D8F] rounded-xl" >WELCOME</span>
                  <h2 className="text-4xl mt-3 font-bold font-heading text-[#264653]">How I Can Help You</h2>
                </div>
                <div className="flex items-start py-4">
                  <div className="w-8 mr-5 text-[#e76f51]">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" aria-hidden="true" className="w-8 h-8">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="mb-2 text-xl font-semibold font-heading text-[#264653] ">Cost</h3>
                    <p className="text-blueGray-400 leading-loose text-[#2A9D8F]" >Get a quality, fast and reliable product for sane prices. <Link className="border-b border-b-[#264653] pb-1 hover:border-b-[#2A9D8F]" to={'/pricing/'}>Check my pricing.</Link></p>
                  </div>
                </div>
                {/* <div className="flex items-start py-4">
                  <div className="w-8 mr-5 text-[#e76f51]">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" aria-hidden="true" className="w-8 h-8">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="mb-2 text-xl font-semibold font-heading text-[#264653] ">Flexability</h3>
                    <p className="text-blueGray-400 leading-loose text-[#2A9D8F]" >I can adapt my schedule to fit you. Do you have a project you need? Get in touch.</p>
                  </div>
                </div> */}
                <div className="flex items-start py-4">
                  <div className="w-8 mr-5 text-[#e76f51]">
                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-arrows-move w-8 h-8" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10zM.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8z" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="mb-2 text-xl font-semibold font-heading text-[#264653] ">Oversight</h3>
                    <p className="text-blueGray-400 leading-loose text-[#2A9D8F]" >You will have the chance to interact with, review and sign off on the product before the work ends.</p>
                  </div>
                </div>
                <div className="flex items-start py-4">
                  <div className="w-8 mr-5 text-[#e76f51]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={30}
                    height={30}
                    fill="currentColor"
                    className="bi bi-bounding-box"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 2V0H0v5h2v6H0v5h5v-2h6v2h5v-5h-2V5h2V0h-5v2H5zm6 1v2h2v6h-2v2H5v-2H3V5h2V3h6zm1-2h3v3h-3V1zm3 11v3h-3v-3h3zM4 15H1v-3h3v3zM1 4V1h3v3H1z" />
                  </svg>

                  </div>
                  <div>
                    <h3 className="mb-2 text-xl font-semibold font-heading text-[#264653] ">Composible design</h3>
                    <p className="text-blueGray-400 leading-loose text-[#2A9D8F] " >Web components built by experts, customized for your vision.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative w-full lg:w-1/2 my-12 lg:my-0">
              <StaticImage src="../../../images/screen.jpg" objectFit='cover' className="relative mx-auto rounded-xl w-full z-10" alt="screen" />
              {/* <img className="relative mx-auto rounded-xl w-full z-10" src="https://images.unsplash.com/photo-1614741118887-7a4ee193a5fa?crop=entropy&cs=tinysrgb&fm=jpg&ixid=MnwzMzIzMzB8MHwxfHNlYXJjaHwxMHx8Y29kaW5nfGVufDB8fHx8MTY2MDE2ODUzNw&ixlib=rb-1.2.1&q=80&w=1920" alt="vdsvsd" /> */}
            </div>
          </div>
        </div>
      </section>


    )
}