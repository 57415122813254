import * as React from 'react';
import { Link } from 'gatsby';


export default function Introduction() {

    return (
        <div className="h-fit sm:h-screen py-12 lg:py-0 align-middle bg-gradient-to-r from-gray-50 to-pink-100 background-animate">
            <div className="flex flex-col lg:flex-row max-w-7xl mx-auto h-full">
                <div className="flex flex-col justify-center">
                    <h1 className="text-center text-5xl font-extrabold text-[#264653] p-4">
                        What makes Salient Applications Special?
                    </h1>

                    <h2 className="text-center text-2xl font-bold text-[#2a9d8f] p-4">
                        Content migration without the headach.
                    </h2>
                </div>
                <div className="self-center p-4 rounded-lg w-3/4">
                    <div className="my-auto">
                        <p className="text-gray-500 align-middle leading-10 font-semibold text-justify">
                            Salient Applications aims to specialise in giving content producers, from bloggers to E-Commerce merchants, 
                            the flexability to leverage thier favourite content management system, while refusing to compromise on quality of design and site performance. <br /> <br /> <span style={{ fontFamily: 'times new roman' }} className="text-[#264653] text-lg tracking-widest font-semibold mr-2">Salient <span className="bg-[#264653] text-white p-1">Applications</span></span>
                            will give you this flexability. <br /> <br /> Package your content with convienent, flexible and performant technology, powered by GatsbyJS. <Link className="border-b border-b-[#264653] pb-1" to='/technology/'>Learn More about our technology.</Link>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}
