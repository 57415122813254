import * as React from 'react';

import * as Components from '../../pageSections/home';
import Template from '../../pageSections/templates/main';
import Head from '../../pageSections/templates/head/standard';
import { CookiesPolicy } from '../../pageSections/Misc';

import { graphql } from 'gatsby';


// create context
// export const HomePageContext = createContext(null);

export default function Home({ data }) {

    const [cookiesPermissionAquired, setCookiesPermissionAquired] = React.useState(true);
    const [siteSettings, setSiteSettings] = React.useState({});

    React.useEffect(() => {
        setSiteSettings(JSON.parse(data.datoCmsWebsite.settings));
        
        const cookiesPermissions = localStorage.getItem('cookiesPermission');
        if (cookiesPermissions) {
            setCookiesPermissionAquired(true);
        } else {
            setCookiesPermissionAquired(false);
        }
    }, []);

    return (
        <Template>
            {/* place your sections here from both Template and Page imports */}
            <Head PageName={'Home'} SiteSettings={JSON.parse(data.datoCmsWebsite.settings)} />
            <Components.Hero />
            <Components.Introduction />
            <Components.FrontendBenefits />
            <Components.BusinessCases />
            <Components.MoreBenefits />
            <Components.Contact />
            {/* {!cookiesPermissionAquired && (
                <CookiesPolicy />
            )} */}
        </Template>
    )
}


export const query = graphql`
    query HomePageQuery($sitename: String) {
        datoCmsWebsite(name: { eq: $sitename }) {
            id
            name
            settings
        }
    }
`