import * as React from 'react';

// Create Folders containing index.js that declare your template components, then import them here and use named exports to make them available to the page components
// import template from your template index.js file, i.e. 'import Template from ../../pageComponents/templates/templateFolder'

// import Component from './component'

import Footer from './Footer'; 


export default function Template(props) {

    return (
        <React.Fragment> 
            {/* surround props here with your template components */}
            
            {props.children}
            <Footer />
        </React.Fragment>
    )
}