import * as React from 'react';
import EmailService from '../../../services/emailService';
import { Formik, Form, Field, useFormik } from 'formik';
import { Link } from 'gatsby';

const validate = values => {

    const errors = {};

    if (!values.firstName.length) {
        errors.firstName = 'Required';
    }

    if (!values.email) {
        errors.email = 'Required';
    }

    if (!values.message) {
        errors.message = 'Required';
    }

    console.log(errors)

    return errors;
}


export default function Contact({ SiteSettings }) {

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: ''
        },
        validate,
        onSubmit: values => {
            console.log(JSON.stringify(values, null, 2));

            EmailService.send(formRef);
        }
    })

    const formRef = React.createRef();

    return (
        
        <section className="relative py-36 overflow-hidden border-t border-t-gray-500 bg-gradient-to-r from-gray-50 to-[#2a9d8f] background-animate">
            <div className="container mx-auto px-4">
                <div className="p-16 bg-gradient-cyan2 rounded-10">
                <div className="flex flex-wrap items-center justify-between -m-6">
                    <div className="w-full md:w-1/2 p-6">
                    <div className="md:max-w-3xl">
                        <h2 className="font-heading font-bold text-5xl sm:text-6xl text-[#264653]">Want to make your vision happen?</h2>
                    </div>
                    </div>
                    <div className="w-full md:w-1/2 p-6">
                    <div className="flex flex-wrap justify-end -m-2">
                        <div className="w-full xl:w-auto p-2">
                            <Link to="/contact/">
                                <button className="font-heading py-5 px-10 block w-full uppercase text-xs tracking-px text-white font-bold border border-white bg-gray-900 hover:bg-white hover:text-gray-900 hover:border-gray-900 rounded-md">Get in touch</button>
                            </Link>
                        </div>
                        <div className="w-full xl:w-auto p-2">
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>


    )
}