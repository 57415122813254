import * as React from 'react';

import { Link } from 'gatsby';

export default function MoreBenefits() {
    return (
        <section className="pt-10 pb-12 md:pb-8">
            <div className="mx-auto max-w-7xl py-8 text-center">
                <h2 className="font-semibold text-7xl pb-10 text-[#264653] text-center">The <span className="px-2 bg-[#e76f51] text-white">Full</span> <span className="border-b pb-1 border-b-[#2a9d8f] text-[#e76f51]">Package</span></h2>
            </div>
        <div className="container px-4 mx-auto max-w-7xl">
            <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-8 md:mb-12">
                <div className="flex items-start">
                <div className="mr-6 p-3 rounded-full text-white bg-[#e76f51]">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                    </svg>
                </div>
                <div className="mt-4">
                    <h3 className="mb-3 text-xl font-semibold font-heading text-[#264653]">TLS Secured</h3>
                    <p className="text-blueGray-400 leading-loose">All traffic to and from your site will be encrypted with modern TLS security certificates, replacing the deprecated SSL certificate standard.</p>
                </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-8 md:mb-12">
                <div className="flex items-start">
                <div className="mr-6 p-3 bg-[#e76f51] rounded-full text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-speedometer w-10 h-10 section-4-icon" viewBox="0 0 16 16">
                    <path d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2zM3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                    <path fillRule="evenodd" d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.945 11.945 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0z" />
                    </svg>
                </div>
                <div className="mt-4">
                    <h3 className="mb-3 text-xl font-semibold font-heading text-[#264653]" >Static Site Generation</h3>
                    <p className="text-blueGray-400 leading-loose" >Enjoy a secure, fast and more reliable website built using GatsbyJS. <Link to={'/technology/'} className="border-b border-b-[#2a9d8f]">Learn more about our technology</Link></p>
                </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-8 md:mb-12">
                <div className="flex items-start">
                <div className="mr-6 p-3 bg-[#e76f51] rounded-full text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-hand-thumbs-up w-10 h-10" viewBox="0 0 16 16">
                    <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                    </svg>
                </div>
                <div className="mt-4">
                    <h3 className="mb-3 text-xl font-semibold font-heading text-[#264653]">Domain Convienence</h3>
                    <p className="text-blueGray-400 leading-loose">Either let Salient Applications aquire, host and manage your domain name, or use your own!</p>
                </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-8 md:mb-12">
                <div className="flex items-start">
                <div className="mr-6 p-3 bg-[#e76f51] rounded-full text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-chat-left-text w-10 h-10" viewBox="0 0 16 16">
                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                </div>
                <div className="mt-4">
                    <h3 className="mb-3 text-xl font-semibold font-heading text-[#264653]" >Easy Communication</h3>
                    <p className="text-blueGray-400 leading-loose" >I'll often be working on a single project at a time. When you work with me, expect a quick response.</p>
                </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-8 md:mb-12">
                <div className="flex items-start">
                <div className="mr-6 p-3 bg-[#e76f51] rounded-full text-white">
                    <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                    </svg>
                </div>
                <div className="mt-4">
                    <h3 className="mb-3 text-xl font-semibold font-heading text-[#264653]">Easy to customize</h3>
                    <p className="text-blueGray-400 leading-loose" >Need to intergrate a chat client, payment provider or a fancy menu? Get in touch to discuss you requirements.</p>
                </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-8 md:mb-12">
                <div className="flex items-start">
                <div className="mr-6 p-3 bg-[#e76f51] rounded-full text-white">
                    <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                </div>
                <div className="mt-4">
                    <h3 className="mb-3 text-xl font-semibold font-heading text-[#264653]" >Lean development</h3>
                    <p className="text-blueGray-400 leading-loose" >As a freelancer I know how my process will deliver your website. No unexpected management Changes!</p>
                </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-8 md:mb-12">
            <div className="flex items-start">
                <div className="mr-6 p-3 bg-[#e76f51] rounded-full text-white">
                    <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                </div>
                <div className="mt-4">
                    <h3 className="mb-3 text-xl font-semibold font-heading text-[#264653]">CMS Compatability</h3>
                    <p className="text-blueGray-400 leading-loose">If you want to migrate your content from say, wordpress or shopify without re-platforming your content, your in the right place</p>
                </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-8 md:mb-12">
            <div className="flex items-start">
                <div className="mr-6 p-3 bg-[#e76f51] rounded-full text-white">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" strokeWidth={2} className="bi bi-stopwatch" viewBox="0 0 16 16">
                    <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z"></path>
                    <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z"></path>
                </svg>
                </div>
                <div className="mt-4">
                    <h3 className="mb-3 text-xl font-semibold font-heading text-[#264653]" >Fast Setup</h3>
                    <p className="text-blueGray-400 leading-loose" >Using gatsbyJS, creating data from an array of disperate sources is streamlined and simple process, saving you time and money.</p>
                </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-8 md:mb-12">
            <div className="flex items-start">
                <div className="mr-6 p-3 bg-[#e76f51] rounded-full text-white">
                    <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                </div>
                <div className="mt-4">
                    <h3 className="mb-3 text-xl font-semibold font-heading text-[#264653]" >Less Complexity</h3>
                    <p className="text-blueGray-400 leading-loose" >The technology I use means id don't worry (too much) about uncertain timescales. Your project delivery is predictable with Salient Applications.</p>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>

    )
}