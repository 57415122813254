import * as React from 'react';


export default function FrontendBenefits() {


    return (
        <section className="relative pt-20 pb-36 overflow-hidden section-3 bg-[#264653]">
            <div className="container mx-auto px-4">
                
                {/* <p className="mb-12 font-heading text-xs bg-[#264653] w-fit rounded-xl border border-[#264653] text-white text-center uppercase tracking-px m-auto p-1 px-2">
                    What makes this a special oppertunity?
                </p> */}
                <h2 className="mb-20 max-w-7xl mx-auto font-heading font-semibold text-center text-6xl sm:text-7xl text-white section-3-sub-heading">
                <span className="text-[#f4a261] font-extrabold" style={{ fontFamily: 'times new roman' }}>Simplicity</span> <span className=" bg-[#f4a261] text-white px-4">without</span> <span className="border-b border-b-[#f3e9e6] pb-1">Compromise</span>
                </h2>
                <div className="flex flex-wrap -m-4 max-w-7xl mx-auto">
                    <div className="w-full md:w-1/3 p-4">
                        <div className="h-full p-9 section-3-card rounded-md border border-[#264653] bg-white">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-full h-12"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="#f4a261"
                            strokeWidth={2}
                            >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                className="bolt"
                                data-path="0.0.2.0.0.0.0"
                            />
                        </svg>


                        <h3 className="font-heading font-bold text-2xl text-[#f4a261] mt-4">
                            <span className="inline md:block lg:inline bg-clip-text bg-gradient-gray pr-2">
                            Responsive
                            </span>
                            <span className="section-3-card-text text-[#264653]">
                            design for a mobile first market.
                            </span>
                        </h3>
                        </div>
                    </div>
                <div className="w-full md:w-1/3 p-4">
                    <div className="h-full p-9 section-3-card rounded-md border border-[#264653] bg-white">
                    <>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-full h-12"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="#f4a261"
                            strokeWidth={2}
                        >
                            <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            className="bolt"
                            />
                        </svg>
                        <h3 className="font-heading font-bold text-white text-2xl"></h3>
                    </>

                    <h3 className="font-heading font-bold text-[#f4a261] text-2xl mt-4">
                        <span className="inline md:block lg:inline bg-clip-text bg-gradient-gray pr-2">
                        SEO
                        </span>
                        <span
                        className="section-3-card-text text-[#264653]"
                        
                        >
                        ready websites, developed to put you first.
                        </span>
                    </h3>
                    </div>
                </div>
                <div className="w-full md:w-1/3 p-4 card">
                    <div
                        className="h-hull p-9 section-3-card rounded-md border border-[#264653] bg-white"
                    
                    >
                    <>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-full h-12"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="#f4a261"
                            strokeWidth={2}
                        >
                            <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13 10V3L4 14h7v7l9-11h-7z"
                            className="bolt"
                            />
                        </svg>
                        <h3 className="font-heading font-bold text-white text-2xl"></h3>
                    </>

                    <h3 className="font-heading font-bold text-[#f4a261] text-2xl mt-4">
                        <span className="inline md:block lg:inline bg-clip-text bg-gradient-gray pr-2">
                        Performance
                        </span>
                        <span className="section-3-card-text text-[#264653]">
                        focused development, for lightning fast loading.
                        </span>
                    </h3>
                    </div>
                </div>
                </div>
            </div>
            </section>

    )
}